<template>
  <v-menu
    ref="moreFiltersMenu"
    v-model="moreFiltersMenu"
    :close-on-content-click="false"
    transition="fade-transition"
    offset-y
    left
    min-width="360px"
    content-class="filter-menu-wrapper"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        data-test="destination-more-filter-btn"
        value="Filters"
        class="filters-more filter-menu__field"
        readonly
        outlined
        prepend-inner-icon="mdi-tune"
        :suffix="getMoreFilterLength.toString() || null"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-card
      data-test="destination-more-filter"
      class="filter-menu filter-more pa-0"
    >
      <div class="text-right pt-2 pr-2">
        <v-icon class="ml-1 text-end" color="light" size="32" @click="closeMoreFilters">
          mdi-close
        </v-icon>
      </div>

      <v-card-title class="inter-font-family pt-0">Filters</v-card-title>
      <div class="px-5">
        <div class="loyalty-section">
          <p class="inter-font-family fs-14 mb-1 fw-600">Your Loyalty Memberships</p>
          <p class="inter-font-family fs-14 fw-400">Prioritise hotels you earn points with </p>
        </div>
      </div>

      <v-card-text>
        <!--<v-subheader>Popular filters</v-subheader>
        <div class="filter-group">
          <v-checkbox
            v-for="item in popularItems"
            :key="item.value"
            :value="item.value"
            :label="item.label"
            v-model="form.mostBooked"
          ></v-checkbox>
        </div>-->
        <!--<v-subheader>
          <template v-if="travelPolicyMax">
            Your travel policy price range
            <v-icon class="ml-1" color="light" size="18">
              mdi-information-outline
            </v-icon>
          </template>
          <template v-else>
            Price range
          </template>
        </v-subheader>
        <price-filters
          v-if="getPriceMinMaxRange && getPriceMinMaxRange[0]"
          v-model="formPrice"
          :travel-policy-max="travelPolicyMax"
          :min="getPriceMinMaxRange[0]"
          :max="getPriceMinMaxRange[1]"
          :currency="getCardsCurrency"
        >
          <v-checkbox
            v-model="form.freeCancellation"
            class="pb-0 pt-3"
            label="Properties with Free Cancellation"
          ></v-checkbox>
        </price-filters>-->

        <p class="filter-subheading inter-font-family mb-2">Brands</p>
        <div class="filter-group">
          <div class="filter-group">
            <v-checkbox
              v-for="item in getBrands"
              :key="item.chain_name"
              :value="item.chain_code"
              :label="item.hotel_filter_name"
              v-model="form.brands"
            ></v-checkbox>
          </div>
        </div>

        <h4 class="inter-font-family mb-2 filter-subheading">Price, {{getAppliedCurrency()}}</h4>
        <div class="filter-group">
          <v-row>
            <v-col class="price_filter_wrapper" cols="6">
            <span>
              <v-text-field
                class="price_filter"
                v-model="form.minPrice"
                hide-details
                single-line
                type="number"
                placeholder="Min"
              />
            </span>
            </v-col>

            <v-col class="price_filter_wrapper" cols="6">
            <span>
              <v-text-field
                class="price_filter"
                v-model="form.maxPrice"
                hide-details
                single-line
                type="number"
                placeholder="Max"
              />
            </span>
            </v-col>
          </v-row>
        </div>

        <p class="filter-subheading inter-font-family mb-2">Popular Filters</p>
        <div class="filter-group">
          <v-checkbox
            v-model="form.freeCancellation"
            label="Properties with Free Cancellation"
          ></v-checkbox>
          <v-checkbox
            v-model="form.breakfastIncluded"
            label="Breakfast Included"
          ></v-checkbox>
          <!--<v-checkbox
            v-for="(item, index) in getAmenitiesItems"
            :key="index"
            :value="item.value"
            :label="item.label"
            v-model="form.amenities"
          ></v-checkbox>
          <a
            class="more-link"
            @click="moreAmenitiesItems = !moreAmenitiesItems"
          >
            {{ moreAmenitiesItems ? 'Hide amenities' : 'Show all amenities' }}
          </a>-->
        </div>

        <p class="filter-subheading inter-font-family mb-2">Hotel star rating</p>
        <div
          class="filter-group filter-star-rating"
          data-test="destination-more-filter-star"
        >
          <v-checkbox
            v-for="index in 5"
            :key="index"
            :value="index"
            v-model="form.starRating"
          >
            <template v-slot:label>
              {{ index }}<v-icon size="18">mdi-star</v-icon>
            </template>
          </v-checkbox>
        </div>
        <div
          v-if="getDistanceMinMaxRange && getDistanceMinMaxRange[0]"
          class="filter-group"
        >
          <p class="filter-subheading inter-font-family mb-5">Distance from centre (km)</p>
          <distance-filter
            v-model="formDistance"
            :min="getDistanceMinMaxRange[0] || 0"
            :max="getDistanceMinMaxRange[1] || 10"
          ></distance-filter>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="lightBg"
          depressed
          rounded
          light
          @click="resetMoreFilters"
        >
          Reset
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          data-test="destination-more-filter-submit"
          color="black"
          depressed
          dark
          rounded
          @click="updateFilters"
        >
          Apply
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import PriceFilters from '@/components/filters/PriceFilters';
import DistanceFilter from '@/components/filters/DistanceFilter';
import { filterFacilities } from '@/utils/facilities';

export default {
  name: 'MoreFilters',
  components: {
    // PriceFilters,
    DistanceFilter
  },
  data() {
    return {
      travelPolicyMax: null,
      form: {
        mostBooked: [],
        price: [],
        starRating: [],
        breakfastIncluded: null,
        amenities: [],
        freeCancellation: null,
        distanceFromCenter: null,
        minPrice: null,
        maxPrice: null,
        brands: []
      },
      moreFiltersMenu: false,
      moreAmenitiesItems: false,
      popularItems: [
        {
          value: 'colleagues',
          label: 'Most booked with colleagues'
        },
        {
          value: 'industry',
          label: 'Most booked in the industry'
        },
        {
          value: 'business',
          label: 'Most booked in business'
        }
      ]
    };
  },
  computed: {
    ...mapGetters('filter', [
      'getMoreFilter',
      'getDistanceMinMaxRange',
      'getMoreFilterLength'
    ]),
    ...mapGetters('hotels', ['getAllItems']),
    ...mapGetters('hotelBrands', ['getBrands']),
    formDistance: {
      get() {
        return (
          this.getMoreFilter.distanceFromCenter ||
          this.getDistanceMinMaxRange[1]
        );
      },
      set(value) {
        this.form.distanceFromCenter = value;
      }
    },
    getAmenitiesItems() {
      if (this.moreAmenitiesItems) {
        return filterFacilities;
      } else {
        return filterFacilities.slice(0, 4);
      }
    }
  },
  methods: {
    ...mapActions('hotels', {
      getCardsContent: 'getCardsContent'
    }),
    ...mapActions('filter', {
      logMoreFiltersEvents: 'logMoreFiltersEvents',
      resetMoreFiltersValues: 'resetMoreFiltersValues',
      setMoreFiltersValues: 'setMoreFiltersValues',
      setPriceMinMaxRange: 'setPriceMinMaxRange'
    }),
    ...mapActions('hotelBrands', {
      setHotelBrands: 'setHotelBrands'
    }),
    closeMoreFilters() {
      this.moreFiltersMenu = false;
    },
    resetMoreFilters() {
      this.moreFiltersMenu = false;
      this.resetMoreFiltersValues();
      this.setValues();
    },
    updateFilters() {
      this.moreFiltersMenu = false;
      this.setMoreFiltersValues(this.form);
      this.logMoreFiltersEvents();
    },
    setValues() {
      if (this.getMoreFilter.price || this.getPriceMinMaxRange) {
        this.form.price = this.getMoreFilter.price || this.getPriceMinMaxRange;
      }
      this.form.starRating = this.getMoreFilter.starRating;
      this.form.brands = this.getMoreFilter.brands;
      this.form.minPrice = this.getMoreFilter.minPrice;
      this.form.maxPrice = this.getMoreFilter.maxPrice;
      this.form.freeCancellation = this.getMoreFilter.freeCancellation;
      this.form.breakfastIncluded = this.getMoreFilter.breakfastIncluded;
      this.form.amenities = this.getMoreFilter.amenities;
      this.form.distanceFromCenter = this.getMoreFilter.distanceFromCenter;
    },
    getAppliedCurrency() {
      if (this.getAllItems && this.getAllItems.length > 0) {
        return this.getAllItems[0].currency;
      } else {
        return '';
      }
    }
  },
  created() {
    this.setValues();
    this.setHotelBrands();
  }
};
</script>
<style lang="scss">
@import 'src/scss/variables';
.v-input.v-text-field.filters-more {
  font-size: 18px;
  line-height: 24px;
  font-weight: 800;
  fieldset {
    border-color: $border;
  }
  input {
    text-align: center;
    color: $light;
  }
  .v-icon {
    color: $text;
  }
  .v-text-field__details {
    display: none;
  }
  .v-input__slot {
    padding: 0 16px !important;
    min-height: 48px;
  }
  &.v-input--is-focused {
    fieldset {
      border: 1px solid $primary;
    }
  }
  &.filter-menu__field {
    .v-input__prepend-inner {
      @media (min-width: 661px) {
        margin-top: 12px;
        padding-right: 0;
      }
    }
    &.v-input--is-focused {
      fieldset {
        border: 1px solid $primary;
        background-color: rgba($primary, 0.2);
      }
      .v-input__prepend-inner,
      .v-icon,
      input {
        color: $primary;
      }
    }
  }
  @media (max-width: 660px) {
    font-size: 14px;
    input {
      font-weight: 800;
      text-align: left;
    }
  }
}
.v-text-field.v-text-field--enclosed {
  .v-text-field__details {
    padding: 0 16px;
  }
}
.filter-menu-wrapper {
  box-shadow: 0 2px 30px 0 rgba($text, 0.2);
  border-radius: 10px;
  .v-card.filter-menu {
    padding: 0;
    box-shadow: none;
  }
  @media (max-width: 660px) {
    min-width: 290px !important;
  }
}
.filter {
  &-menu {
    &.v-card {
      max-width: 400px;
      border-radius: 10px;
    }
    .v-card__actions {
      border-top: 1px solid $border;
      padding: 16px;
    }
    .v-card__title {
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      padding: 24px 24px 16px;
    }
    .v-card__text {
      padding: 10px 24px !important;
    }
    .v-label {
      font-size: 14px;
      font-weight: 600;
    }
    .v-text-field__details,
    .v-messages {
      display: none;
    }
    .v-subheader {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      padding: 0;
      height: auto;
      margin-bottom: 8px;
    }
    .loyalty-section {
      border-bottom: 1px solid #dbdbdb;
    }
    .filter-subheading {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      color: #252C32;
    }
    .price_filter_wrapper {
      padding-top: 0;
    }
    .price_filter {
      padding-top: 0;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
      }

      input[type=number] {
        -moz-appearance: textfield; /* Firefox */
      }

      .v-text-field__slot {
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 5px;
        &:after{
          border-color: transparent;
          border: 0;
        }
      }

      .v-input__slot::before,
      .v-input__slot::after {
        display: none !important;
      }
    }
    .v-input {
      margin-top: 0;
      &__slot {
        margin: 0;
      }
      &.v-input--checkbox {
        padding: 0 0 10px;
      }
    }
  }
  &-group {
    &:not(:last-child) {
      border-bottom: 1px solid $border;
      padding-bottom: 10px !important;
      margin-bottom: 8px !important;
    }
    .v-input.v-input--checkbox {
      .v-label {
        color: $text;
      }
    }
    .row {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &-star-rating {
    display: flex;
    .v-input.v-input--checkbox {
      background-color: #fff;
      border: 1px solid $border;
      border-radius: 10px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      transition: all 0.2s;
      flex: 1 1 64px;
      padding-top: 0;
      padding-bottom: 0;
      &:not(:last-child) {
        margin-right: 8px;
      }
      .v-input__slot {
        margin: 0;
      }
      .v-input--selection-controls__input {
        opacity: 0;
        position: absolute;
      }
      .v-label {
        color: $text;
        text-align: center;
        justify-content: center;
        font-size: 18px;
        line-height: 24px;
        transition: all 0.2s;
      }
      .v-icon {
        color: currentColor;
        margin-left: 4px;
        transition: none;
      }
      &.v-input--is-label-active {
        background-color: $primary;
        border-color: $primary;
        .v-label {
          color: #fff;
        }
      }
    }
  }
  &-amenities {
    display: flex;
    flex-wrap: wrap;
    .v-radio,
    > .v-input {
      flex: 0 0 50%;
      max-width: 50%;
      width: 50%;
      margin: 0;
    }
    .more-link {
      color: $light;
      text-decoration: underline;
      font-weight: 600;
    }
    .v-input--radio-group {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
      padding: 0;
      .v-input--radio-group__input {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
      }
      .v-radio {
        margin-bottom: 10px;
      }
      .v-label {
        color: $text;
      }
    }
  }
}
</style>

<template>
  <div class="results__heading">
    <div class="results_heading_filter_section">
      <div class="results__heading--filters mr-0 ml-auto">
        <more-filters></more-filters>
      </div>
    </div>
    <v-btn
      data-test="destination-map-btn"
      :rounded="!isMap"
      :fab="isMap"
      large
      depressed
      color="lightBg"
      @click="toggleMap"
    >
      <span v-if="isMap">
        <v-icon>mdi-close</v-icon>
      </span>
      <span v-else>
        <v-icon left>mdi-map</v-icon>
        Map view
      </span>
    </v-btn>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import MoreFilters from '@/components/filters/MoreFilters';

export default {
  name: 'DestinationHeading',
  components: {
    MoreFilters
  },
  computed: {
    ...mapState('filter', ['destination']),
    ...mapState('destination', ['isMap'])
  },
  methods: {
    ...mapActions('destination', ['toggleMap'])
  }
};
</script>
<style lang="scss" scoped>
@import 'src/scss/utils/__utils';
.results__heading {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 12px 0;
  position: sticky;
  top: $header-height;
  z-index: 6;
  &--filters {
    width: 190px;
  }
  .v-btn {
    margin-left: 16px;
    color: $light;
    &:not(.v-btn--round).v-size--large {
      height: 48px;
    }
    &.v-btn--fab {
      width: 48px;
      height: 48px;
    }
  }
  @media (min-width: 768px) {
    margin-left: auto;
  }
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    top: 120px;
    margin: 30px 0 0 -12px;
    padding-left: 12px;
    padding-right: 12px;
    width: calc(100% + 24px);
    &--filters {
      width: 190px;
      margin-right: auto;
    }
    .v-btn {
      &:not(.v-btn--round).v-size--large {
        height: 40px;
      }
      &.v-btn--fab {
        width: 40px;
        height: 40px;
      }
    }
  }
  @include queryMax(374px) {
    top: $header-height-sm;
  }
}
.results_heading_filter_section {
  @media (min-width: 768px) {
    display: none;
  }
}
</style>

import { render, staticRenderFns } from "./DestinationHeading.vue?vue&type=template&id=5b0d4bc0&scoped=true"
import script from "./DestinationHeading.vue?vue&type=script&lang=js"
export * from "./DestinationHeading.vue?vue&type=script&lang=js"
import style0 from "./DestinationHeading.vue?vue&type=style&index=0&id=5b0d4bc0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b0d4bc0",
  null
  
)

export default component.exports
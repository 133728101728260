<template>
  <div class="results">
    <destination-heading v-if="!isHeadingLoading"></destination-heading>
    <div v-if="getCardsLoading" class="row row-loading">
      <div class="hotel-sidebar d-none d-sm-block col-md-3 col-lg-2">
        <hotel-sidebar-filters></hotel-sidebar-filters>
      </div>
      <div class="col-md-3 col-lg-2">
        <div v-for="i in 9" :key="i" class="">
          <generic-card-skeletion></generic-card-skeletion>
        </div>
      </div>
      <div class="col-md-6 col-lg-8">
        <div class="card-skeleton" style="height: calc(100% - 16px);">
          <div class="skeleton-banner glassy-item" style="height: 100%;">
            <div class="glass-animaton"></div>
          </div>
        </div>
      </div>
      
    </div>
    <div v-else class="results-row">
      <v-row>
        <div class="hotel-sidebar d-none d-sm-block col-md-3 col-lg-2">
          <hotel-sidebar-filters></hotel-sidebar-filters>
        </div>
        <div
          :class="
            isMap
              ? 'col-sm-6 col-md-3 col-lg-2'
              : 'col-sm-12 col-md-9 col-lg-10'
          "
        >
          <v-row>
            <v-col
              v-for="(item, i) in getItemsToShow"
              :key="item.extId + ' ' + i"
              :md="isMap ? 12 : 4"
              :sm="isMap ? 12 : 6"
              cols="12"
            >
              <!--<food-and-drink-card
                :data="item"
                v-if="getSelectedTabKey === 'restaurants'"
              ></food-and-drink-card>
              <car-rental-card
                :data="item"
                v-if="getSelectedTabKey === 'transport'"
              >
              </car-rental-card>-->
              <generic-card
                v-if="getSelectedTabKey !== 'restaurants'"
                data-test="destination-results-card"
                class="results-row__link"
                :data="item"
                :active="isActiveCard(item.extId)"
                :hover="isHoverCard(item.extId)"
                :id="item.extId"
                @mouseover.native="setHoverCard(item.extId, i + 1)"
                @mouseleave.native="setHoverCard()"
                @click.native="hotelTileClick(item)"
              ></generic-card>
            </v-col>

            <v-btn
              v-if="!getEndOfResults"
              color="primary"
              class="ml-auto mr-auto lightBg d-none d-sm-block"
              :disabled="getAllResultsFetched"
              text
              :loading="getLoadingMore"
              @click="handleViewMore()"
            >
              <div v-if="getAllResultsFetched">
                All available properties are now showing
              </div>
              <div v-else>
                View More Hotels
              </div>
            </v-btn>
          </v-row>
        </div>
        <div
          :class="isMap ? 'col-sm-6 col-md-6 col-lg-8' : 'col-12'"
          v-if="isMap"
          class="results__map"
        >
          <custom-map
            data-test="destination-map"
            class="results__mapbox"
            :center="centerMapPoint"
            :zoom="11"
            :markers-bounds="getMarkersBounds"
          >
            <div
              v-for="(item, i) in getItemsToShow"
              :key="item.extId + ' ' + i"
              :class="{ active: isActiveCard(item.extId) }"
            >
              <mgl-marker :coordinates="[item.lng, item.lat]" anchor="bottom">
                <div
                  slot="marker"
                  @mouseover="setHoverCard(item.extId + ' ' + i)"
                  @mouseleave="setHoverCard()"
                  @click="setActiveCard($event, item.extId)"
                >
                  <map-marker
                    data-test="destination-map-pin"
                    :id="'hotel-marker-' + item.extId"
                    :price="getMarkerPrice(item)"
                  ></map-marker>
                </div>
              </mgl-marker>
            </div>
          </custom-map>
        </div>
      </v-row>
    </div>
    <div
      v-if="!getCardsLoading && getItemsToShow.length > 0"
      class="d-flex align-center mt-4 col-12 pa-0"
    >
      <div>
        *Includes taxes & fees
      </div>
      <v-btn
        v-if="!getEndOfResults"
        color="primary"
        class="ml-auto d-sm-none d-block"
        :disabled="getAllResultsFetched"
        text
        :loading="getLoadingMore"
        @click="handleViewMore()"
      >
        <div v-if="getAllResultsFetched">
          All available properties are now showing
        </div>
        <div v-else>
          View More Hotels
        </div>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import DestinationHeading from '@/components/destination/DestinationHeading';
import { getMapBounds, getCenterBounds } from '@/utils/map';
import { connectors } from '@/utils/connectors';
import { logMixPanelEvent, mixPanelEvent } from '../utils/mixpanel';
import HotelSidebarFilters from './filters/HotelSidebarFilters.vue';

export default {
  name: 'ResultsPane',
  components: {
    HotelSidebarFilters,
    DestinationHeading,
    GenericCard: () => import('@/components/cards/GenericCard'),
    // FoodAndDrinkCard: () => import('@/components/cards/FoodAndDrinkCard'),
    // CarRentalCard: () => import('@/components/cards/CarRentalCard'),
    MglMarker: () => import('vue-mapbox/src/components/UI/Marker'),
    CustomMap: () => import('@/components/elements/map/CustomMap'),
    MapMarker: () => import('@/components/elements/map/MapMarker'),
    GenericCardSkeletion: () =>
      import('./cards/skeletons/GenericCardSkeletion.vue')
  },
  data() {
    return {
      activeCardId: null,
      hoverCardId: null,
      hoverCardPosition: null
    };
  },
  computed: {
    ...mapState('destination', ['isMap', 'destinationInfoLoading']),
    ...mapGetters('home', ['getSelectedTabKey']),
    ...mapGetters('hotels', {
      getItemsToShow: 'getItemsToShow',
      getLoading: 'getLoading',
      getEndOfResults: 'getEndOfResults',
      getCardsLoading: 'getLoading',
      getLoadingMore: 'getLoadingMore',
      getFiltered: 'getFiltered',
      getHotelRoomPrices: 'getHotelRoomPrices',
      getAllResultsFetched: 'getAllResultsFetched'
    }),
    ...mapGetters('destination', ['getDestinationInfoPlace']),
    ...mapGetters('filter', ['getMoreFilter', 'getMoreFilterActive']),
    ...mapGetters('user', ['userId', 'activeTripId', 'getLocationCoordinates']),
    getMarkersBounds() {
      return getMapBounds(this.getItemsToShow);
    },
    centerMapPoint() {
      return getCenterBounds(this.getMarkersBounds);
    },
    isHeadingLoading() {
      return this.destinationInfoLoading || this.getLoading;
    }
  },
  methods: {
    ...mapActions('hotels', {
      getCardsContent: 'getCardsContent',
      handleViewMore: 'handleViewMore'
    }),
    ...mapActions('destination', ['toggleMap']),
    isActiveCard(id) {
      return id === this.activeCardId;
    },
    isHoverCard(id) {
      return id === this.hoverCardId;
    },
    setHoverCard(id = null, position) {
      Array.from(
        document.querySelectorAll('.mapboxgl-marker.hover')
      ).forEach(el => el.classList.remove('hover'));

      if (id) {
        const marker = document.getElementById('hotel-marker-' + id);

        if (marker) {
          marker.closest('.mapboxgl-marker').classList.add('hover');
        }
      }

      if (this.hoverCardId !== id) {
        this.hoverCardId = id;
        this.hoverCardPosition = position;
      }
    },
    setActiveCard(e, id = null) {
      Array.from(
        document.querySelectorAll('.mapboxgl-marker.active')
      ).forEach(el => el.classList.remove('active'));

      if (id) {
        const elementById = document.getElementById(id);
        window.scrollTo(0, elementById.offsetTop - 72);
        e.target.closest('.mapboxgl-marker').classList.add('active');
      }

      this.activeCardId = this.activeCardId === id ? null : id;
    },
    getMarkerPrice(hotel) {
      if (hotel.source === connectors.zentrumhub) {
        const hotelRate = this.getHotelRoomPrices[hotel.extId];
        if (!hotelRate || !hotelRate.price) {
          return null;
        }
        return `${hotelRate.currency}${hotelRate.price}`;
      }

      return `${hotel.currency}${Math.round(hotel.price)}`;
    },
    hotelTileClick(item) {
      logMixPanelEvent(
        mixPanelEvent.hotelEvents.hotelCardClck,
        {
          distinct_id: this.userId,
          userId: this.userId,
          activeTripId: this.activeTripId,
          position: this.hoverCardPosition,
          original_position: item.original_position,
          hotel: {
            id: item.extId,
            name: item.name,
            currency: item.currency
          }
        },
        this.getLocationCoordinates
      );
    }
  },
  created() {
    if (!this.getItemsToShow.length && !this.getCardsLoading) {
      this.getCardsContent();
    }
  }
};
</script>
<style lang="scss">
@import 'src/scss/utils/__utils';

.mapboxgl-map {
  width: 100%;
  height: 100%;
}

.row-loading {
  display: flex;
  justify-content: center;
  min-height: 200px;
  padding: 50px 0;
}

.results {
  display: flex;
  flex-wrap: wrap;

  &__title {
    margin-right: auto;
    flex-basis: 0;
    flex-grow: 1;
    background-color: #fff;
  }

  &-row {
    width: 100%;

    .hotel-sidebar {
      position: sticky;
      top: 105px;
      left: 0;
      align-self: flex-start;

      .filter-group {
        padding-bottom: 10px !important;
        margin-bottom: 8px !important;
      }

      @media (max-width: 768px) {
        position: static;
      }
    }

    &__link {
      display: block;
      height: 100%;

      &[disabled] {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    @include query-767 {
      margin-top: 20px;
    }
  }

  &__map {
    position: sticky;
    top: 133px;
    align-self: flex-start;
    border-radius: 10px;
    overflow: hidden;

    .results__mapbox.mapbox {
      background-color: $light;
      height: calc(100vh - 100px - #{$header-height});
    }
  }

  @include queryMin(768px) {
    &__title {
      margin-left: -25px;
      padding-left: 25px;
      position: sticky;
      top: $header-height;
      z-index: 6;
      display: flex;
      align-items: center;
      padding-right: 16px;

      .text-primary {
        margin-left: 7px;
      }
    }
  }

  @include query-991 {
    h2 {
      font-size: 18px;
      line-height: 24px;
    }
  }

  @include query-767 {
    padding-top: 32px;

    h2 {
      font-size: 18px;
      margin-bottom: 4px;
      width: 100%;
      line-height: 24px;
    }
  }
}
</style>
